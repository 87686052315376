import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import React, { FC, useState, useEffect } from 'react'
import { tawreedTranslationService } from '../../../../../common/translations';
import { setFocus, TawreedFormField, TawreedInputNumber } from "../../../../../common/components/form";
import { CartService, salesOfferDetailsDto } from '../../domain';
import { CssUtils } from '../../../../../helpers/css';
import { Panel } from 'primereact/panel';
import { ObjectUtils } from '../../../../../helpers/object';
import { AuthUtils } from '../../../../auth/domain';
import { StoreProduct } from '../../../../catalog/store-products/data';

import './cart-form.scss';
import { GlobalContext } from '../../../../../context';

export interface SalesOfferDetailsDialogProps {
    visible: boolean;
    salesOfferId: number;
    salesOfferTitle?: string;
    expirationDate?: string;
    salesOfferDescription?:string;
    storeName?:string;

    hide: () => void;

    accept: () => void;
}

export const SalesOfferDetailsDialog: FC<SalesOfferDetailsDialogProps> = ({ visible, hide, accept, salesOfferId, salesOfferTitle, expirationDate, salesOfferDescription,storeName }) => {

    const service: CartService = React.useMemo(() => new CartService(), []);
    const [details, setDetails] = useState<Array<salesOfferDetailsDto>>([])
    const [loader, setLoader] = useState<boolean>(false);
    const lang = AuthUtils.current().language;
    
    const { constants: { constants } } = React.useContext(GlobalContext);



    useEffect(()=>{
        setLoader(true);
        if (salesOfferId) {

            service.getPackageDetails(salesOfferId).then(res => {
                setDetails(res)
                setLoader(false);
                //changeQuantity(quantity, bonusType);
            });
        }
    },[visible]);



    const BonusDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" />
            <Button onClick={() => accept()} label={tawreedTranslationService.translate('lbl_accept')} icon="pi pi-check" />
        </div>);
    }

    return (

        <Dialog maximizable style={{ maxWidth: '512px', minWidth: '55%' }} header={salesOfferTitle} footer={<BonusDialogFooter />}  visible={visible} onHide={hide} draggable={false} position="top" closable={true} closeOnEscape={true}>
            <div className="flex flex-wrap">
            <div className="col-12 md:col-12">
            <Panel>
                        <div className="grid">
                            
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_sales_offer_title')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                               {salesOfferTitle}
                            </div>
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_sales_offer_description')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {salesOfferDescription}
                            </div>
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_sales_offer_expire_date')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {expirationDate}
                            </div>
                            
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_sales_offer_store_name')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                               {storeName}
                            </div>
                          
                        </div>
                    </Panel>
            </div>
            
                {
                details.map(el =>{
                    return <div className="col-6 md:col-6" key={el.itemId}>
                    <Panel header={lang === 'en' ? el.titleEn : el.titleAr}>
                        <div className="grid">
                            
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_price')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                { ObjectUtils.toLocalString(el.price) + (lang === "en" ? "JOD " : " د.أ")}
                            </div>
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_price_scheme_discountType')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {constants?.discountTypes.find(e => e.value === el.discountType)?.label}
                            </div>
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_price_scheme_discountValue')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {ObjectUtils.toLocalString(el.discountValue) + ObjectUtils.toDiscount(el.discountType!,lang )}
                            </div>
                            
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_tax')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {'% ' + ObjectUtils.toLocalString(el.tax)}
                            </div>
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_quantity')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {ObjectUtils.toLocalString(el.quantity)}
                            </div>
                        </div>
                    </Panel>
                    </div>
                })}


            </div>
        </Dialog>
    )
}
