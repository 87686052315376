import React from 'react'

import { Button } from 'primereact/button';

import { TawreedFile } from '../../../domain/dtos';
import { computeTawreedActionTitle, TawreedAction } from '../../../../../common/actions';
import { AuthUtils } from '../../../../auth/domain';
import { tawreedTranslationService } from '../../../../../common/translations';

export type TawreedFilesListItemProps =
    {
        /**
         * 
         */
        file: TawreedFile;

        /**
         * 
         */
        className?: string;

        /**
         * 
         */
        actions?: Array<TawreedAction<TawreedFile>>;
    };

export const TawreedFilesListItem: React.FC<TawreedFilesListItemProps> = ({ file, className, actions }) => {

    const [executing, setExecuting] = React.useState(false);
    const [aa, setAa] = React.useState<Array<TawreedAction<TawreedFile>>>();

    React.useEffect(
        () => {
            let mounted = true;
            if (mounted) {

                const ee = actions?.filter(e => AuthUtils.current().authorized(e.name));
                if (ee && ee.length) {
                    setAa(ee);
                } else {
                    setAa(undefined);
                }
            }
            return () => { mounted = false; }
        },
        [actions]);


    const execute = (e: TawreedAction<TawreedFile>) => {
        setExecuting(true);
        e.execute(file).finally(() => setExecuting(false));
    }

    return (
        
        <div className={`files-list-item ${className}`} role="none">
        {
            aa && aa.length===1 ? 
            
            <div className="files-list-item-actions">
                    {
                        aa.map((a) => <Button type="button" key={a.name} title={tawreedTranslationService.translate(computeTawreedActionTitle({}, a.title))} icon={a.icon} className={'p-button p-button-outlined p-button-secondary ml-2'} disabled={executing} onClick={() => execute(a)} label={file.fileName} />)
                    }
                </div>
                :

                <div className="flex flex-column md:flex-row align-items-center">
                <span className="mr-auto" style={{ fontWeight: file.value instanceof File ? 'bold' : 'normal' }}>{file.fileName}</span>
                {
                    aa &&
                    aa.length &&
                    <div className="files-list-item-actions">
                        {
                            aa.map((a) => <Button type="button" key={a.name} title={tawreedTranslationService.translate(computeTawreedActionTitle({}, a.title))} icon={a.icon} className={a.className} disabled={executing} onClick={() => execute(a)} />)
                        }
                    </div>
                }
            </div>   
        }
        </div>
    );
}
