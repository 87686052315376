import { PrimeIcons } from "primereact/api";
import { Panel } from "primereact/panel";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { TawreedAction, TawreedActionButton, TawreedActionName } from "../../../../../common/actions";
import { TawreedFormMode} from "../../../../../common/components/form";
import { tawreedTranslationService } from "../../../../../common/translations";
import { Routes } from "../../../../../router";
import { StoreProductDto, StoreProductsService, priceListDto, PriceSchemeService, priceSchemeDto } from "../../domain";
import { PriceListServcie } from "../../domain/services/price-list.service";
import { PriceListForm } from "./price-list-form";
import { toastService } from "../../../../notifications/domain";
import { GlobalContext } from "../../../../../context";
import { ProductDto, ProductsService } from "../../../products/domain";
import { CommissionService } from "../../../../partners/domain";
import { AuthUtils } from "../../../../auth/domain";
import { Steps } from "primereact/steps";
import { MenuItem } from "primereact/menuitem";
import { JObject } from "../../../../../common/data/models";
import { BasicInfoForm } from "./basic-info-form";
import { Toolbar } from "primereact/toolbar";
import { QuantityInfoForm } from "./quantity-info-form";

export const StoreProductForm: FC = (_props) => {
    const { id } = useParams<{ id: string | undefined }>();
    const { pid } = useParams<{ pid: string | undefined }>();

    const defaultRef = useRef(null);
    const creditRef = useRef(null);
    const ref = useRef<{ submit: () => void }>();
    const [activeIndex, setActiveIndex] = useState(0);
    const [array, setArray] = useState<priceSchemeDto[]>([]);

    // di
    const service: StoreProductsService = useMemo(() => new StoreProductsService(), []);
    const productService: ProductsService = React.useMemo(() => new ProductsService(), []);

    const priceListService: PriceListServcie = useMemo(() => new PriceListServcie(), []);

    const priceSchemeService: PriceSchemeService = useMemo(() => new PriceSchemeService(), []);
    const commissionService: CommissionService = useMemo(() => new CommissionService(), []);

    const { auth: { user }, constants: { constants } } = React.useContext(GlobalContext);


    const [loading, setLoading] = useState<boolean>(false);
    const [mode, setMode] = React.useState<TawreedFormMode>("Create");
    const [defaultMode, setDefaultMode] = useState("Create");
    const [creditMode, setCreditMode] = useState("Create");
    const [data, setData] = useState<StoreProductDto>({
        active: true,
    });
    const [priceLists, setPriceLists] = useState<Array<priceListDto>>([]);

    const history = useHistory();

    const [product, setProduct] = React.useState<ProductDto>({});


    useEffect(() => {
        let mounted = true;
        if (id) {
            if (mounted) {
                setMode("Edit");
                setLoading(true);
                setCreditMode("View");
                setDefaultMode("View");
            }
            service
                .getDetails(Number.parseInt(id))
                .then((res) => {
                    if (mounted) {


                        setData(res);
                        productService.getDetails(res.product?.productId!)
                            .then(res2 => {
                                setProduct(res2);
                            })
                            .catch(() => {
                            })
                    }
                })
                .catch(() => {
                    if (mounted) {
                        setLoading(false);
                    }
                });

            priceListService.getAll().then((res) => {
                setPriceLists(res);
                var newArray: priceSchemeDto[] = [];
                res.forEach(price => {
                    priceSchemeService.getDetails(price.priceListId, Number.parseInt(id))
                        .then(res => {
                            const idx = array.findIndex((el) => el.priceListId === price.priceListId);
                            var item = {};
                            if (res.priceSchemeId) {

                                item = {
                                    ...res, price: res.price ?? data.price,
                                    priceListId: price.priceListId, storeProductId: Number.parseInt(id)
                                };
                            }
                            else {
                                item = {
                                    ...res, price: res.price ?? data.price, discountValue: 0, discountType: "fixed", bonuses: [],
                                    priceListId: price.priceListId, storeProductId: Number.parseInt(id)
                                };
                            }

                            if (idx >= 0) {
                                newArray[idx] = item;
                            }
                            else
                                newArray.push(item);

                            setLoading(false);
                            setArray([...newArray]);
                        }).catch(err => {
                            setLoading(false);
                        });
                });
            });

        } else {
            if (mounted) {
                setMode("Create");
                setCreditMode("Create");
                setDefaultMode("Create");
                priceListService.getAll().then((res) => {
                    setPriceLists(res);
                    var newArray: priceSchemeDto[] = [];
                    res.forEach(price => {

                        var item = {
                            bonuses: [],
                            priceListId: price.priceListId, storeProductId: -1
                        };

                        newArray.push(item);
                        setArray([...newArray]);
                    });
                });
            }
            if (pid) {
                setLoading(true);
                productService.getDetails(Number.parseInt(pid))
                    .then(res2 => {
                        setProduct(res2);
                        setData({
                            ...data,
                            product: res2,
                            titleAr: res2.productName,
                            titleEn: res2.productNameEn,
                            tax: res2.productTax,
                            price: res2.price,
                            storeItemId: res2.internationalCode,
                            store: { partnerId: user!.partnerId }
                        })
                        setLoading(false);
                    })
                    .catch(() => {
                        setLoading(false);
                    })
            }
        }
        //setData({ ...data, store: { partnerId: user!.partnerId } })

        return () => {
            mounted = false;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, service, priceListService,pid]);

    useEffect(() => {
        if (pid) {
            setLoading(true);
            productService.getDetails(Number.parseInt(pid))
                .then(res2 => {
                    setProduct(res2);
                    setData({
                        ...data,
                        product: res2,
                        titleAr: res2.productName,
                        titleEn: res2.productNameEn,
                        tax: res2.productTax,
                        price: res2.price,
                        storeItemId: res2.internationalCode,
                        store: { partnerId: user!.partnerId }
                    })
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                })
        }
    }, [pid]);

    useEffect(() => {
        array.forEach(el => {
            el.price = data.price
        })
    }, [data.price])

    const getSellingPrice = (el: any) => {

        let discountType = 'fixed';
        if (el.discountType === 'percentage')
            discountType = 'percentage';
        if (el.discountType === 'bonus')
            discountType = 'bonus';
        let discountValue = 0;
        if (el.discountValue)
            discountValue = el.discountValue;
        let sellingPrice = el.price!;
        sellingPrice -= (discountType === 'fixed' ? discountValue : (el.price! * discountValue / 100));
        if (discountType === 'bonus')
            sellingPrice = el.price! - (el.price! * (discountValue / (discountValue + 100)));
        return sellingPrice;

    }

    const onCreateOrSave = async (datas: any, cb: any): Promise<void> => {
        //@ts-ignore
        //defaultRef.current?.click();
        //@ts-ignore
        //creditRef.current.click();
        let ok = false;
        array.forEach((el) => {
            if (el.price && el.price > 0 && el.discountType && el.discountValue! >= 0)
                ok = true;
        })

        if (!ok) {
            toastService.show({ detail: tawreedTranslationService.translate('lbl_price_list_error'), severity: 'error' });
            return new Promise<void>((resolve, reject) => {
                reject();
            });
        }
        ok = true;
        setLoading(true);

        for await (const item of array) {
            if (item.price && item.price > 0) {
                const value = getSellingPrice(item);
                const result = await commissionService.checkPrice({ partnerId: data.store?.partnerId, priceListId: item.priceListId, value: getSellingPrice(item) });
                if (!result)
                    ok = false;
            }
        }
        if (!ok) {
            toastService.show({ detail: tawreedTranslationService.translate('lbl_price_list_commission_error'), severity: 'error' });
            return new Promise<void>((resolve, reject) => {
                setLoading(false);
                reject();
            });

        }
        data.expireDate?.setTime(data.expireDate.getTime() + (3 * 60 * 60 * 1000));
        return service
            .createOrUpdate(data)
            .then(async (res) => {
                for await (const priceSchemeDto of array) {
                    if (priceSchemeDto.price! > 0 && priceSchemeDto.discountType && priceSchemeDto.discountValue! >= 0)
                        await priceSchemeService.createOrUpdate({ ...priceSchemeDto, storeProductId: res.storeProductId })
                }
                setLoading(false);
                if (!data.storeProductId && res.storeProductId) {
                    history.replace(Routes.StoreProductsDetails.replace(":id", res.storeProductId.toString()));
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const onDelete = () => {
        setLoading(true);
        return service
            .delete(data.storeProductId!)
            .then((res) => {
                setLoading(false);
                if (res) {
                    history.replace(Routes.StoreProductsSearch);
                }
            })
            .catch((err) => {
                setLoading(false);
                throw err;
            });
    };

    const onBack = () => {
        return new Promise<void>((res, rej) => {
            setActiveIndex(activeIndex - 1);
            res()
        })
    }
    const onBackToCatalog =()=>{
        return new Promise<void>((res, rej) => {
        history.goBack();
        });
    }
    const onNext = () => {
        return new Promise<void>((res, rej) => {
            if (activeIndex == 0) {
                if (mode == 'Create')
                    ref.current?.submit();  
                else
                    setActiveIndex(activeIndex+1);
            }
            else{
            setActiveIndex(activeIndex+1);
            }
            res();
        })
    }
    var actions = [
        new TawreedAction(TawreedActionName.Back,  "statefull", "lbl_back_to_catalog", PrimeIcons.ARROW_LEFT, onBackToCatalog, "p-button-outlined ml-1")
    ];
    if (id) {
        actions.push(new TawreedAction(TawreedActionName.StoreProductsDelete, "statefull", "lbl_delete", PrimeIcons.TRASH, onDelete, "p-button-danger p-button-outlined ml-1", "confirmDelete"));
    }
    else {
        if (activeIndex === 2) {
            actions.push(new TawreedAction(TawreedActionName.StoreProductsSubmit, "statefull", "lbl_save", PrimeIcons.PLUS, onCreateOrSave, "p-button-outlined ml-1"));
        }
    }
    
    if (activeIndex !== 0) {
        actions.push(new TawreedAction(TawreedActionName.StoreProductsDelete, "statefull", "lbl_previous", PrimeIcons.CHEVRON_LEFT, onBack, "p-button-secondary p-button-outlined ml-1",undefined));
    }
    if (activeIndex !== 2) {
        actions.push(new TawreedAction(TawreedActionName.StoreProductsSubmit, "statefull", "lbl_next", PrimeIcons.CHEVRON_RIGHT, onNext, "p-button-secondary p-button-outlined ml-1"));
    }
    const itemRenderer = (item: JObject, itemIndex: number) => {
        const isActiveItem = activeIndex === itemIndex;
        const backgroundColor = isActiveItem ? 'var(--primary-color)' : 'var(--surface-b)';
        const textColor = isActiveItem ? 'var(--surface-b)' : 'var(--text-color-secondary)';

        return (
            <React.Fragment>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <span
                        className="inline-flex align-items-center justify-content-center align-items-center border-circle border-primary border-1 h-3rem w-3rem z-1 cursor-pointer"
                        style={{ backgroundColor: backgroundColor, color: textColor, marginTop: '0px' }}
                        onClick={() => {

                            if (!isActiveItem) {
                                if (activeIndex == 0) {
                                    if (mode == 'Create')
                                        ref.current?.submit();
                                    else
                                        setActiveIndex(itemIndex);
                                }
                                else{
                                    setActiveIndex(itemIndex);
                                }
                            }
                        }}
                    >
                        {mode === 'Edit' ? <i className={`${item.icon} text-xl`} /> : itemIndex + 1}

                    </span>
                    <p className='mt-2' style={{ fontWeight: activeIndex === itemIndex ? 700 : 400 }}>{
                        itemIndex === 0 ?
                            `${tawreedTranslationService.translate("lbl_general_information")}` :
                            itemIndex === 1 ?
                            `${tawreedTranslationService.translate("lbl_quantity_trackin")}` :
                            `${tawreedTranslationService.translate('lbl_price_scheme_price_list_name')}`
                    }</p>
                </div>

            </React.Fragment>
        );
    };
    const saveProduct = (storeProduct: StoreProductDto) => {
        setData({ ...storeProduct });
        setActiveIndex(1);
    }
    const saveProductWithoutChange = (storeProduct: StoreProductDto) => {
        setData({ ...storeProduct });
    }
    const items: MenuItem[] = [
        {
            icon: 'pi pi-check-circle',
            template: (item) => itemRenderer(item, 0),


        },
        {
            icon: 'pi pi-plus',
            template: (item) => itemRenderer(item, 1)
        },
        {
            icon: 'pi pi-dollar',
            template: (item) => itemRenderer(item, 2)
        },
    ];
    return (
        <React.Fragment>
            <Toolbar className="form-tawreed-toolbar"
                left={
                    <React.Fragment>
                        <span></span>
                        <h4>{data.titleEn ? (AuthUtils.current().language === 'en' ? data.titleEn : data.titleAr) : tawreedTranslationService.translate("lbl_store_product_new")}</h4>
                    </React.Fragment>
                }
                right={
                    <React.Fragment>
                        <div className="md:flex">
                            {
                                actions.map(((e, index) => <TawreedActionButton key={index} type="button" action={e} />))
                            }
                        </div>
                    </React.Fragment>
                } />
            <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} className="m-2 pt-4" />
            <div>
                {activeIndex === 0 &&
                    <BasicInfoForm
                        id={id}
                        initLoading={loading}
                        baseProduct={product}
                        ref={ref}
                        product={data}
                        mode={mode}
                        accept={saveProduct}
                        saveProduct={saveProductWithoutChange}
                    ></BasicInfoForm>
                }
                {activeIndex === 1 && <Panel>
                    <QuantityInfoForm
                    initloading={loading}
                    mode={mode}
                    id={id}
                    onSaveProductQuantity={(quantityData)=>{
                        setData({
                            ...data,
                            quantity: quantityData.quantity,
                            maximumOrderQuantity: quantityData.maxqty,
                            minimumOrderQuantity: quantityData.minqty
                        })
                    }}
                    productQauntity={{quantity:data.quantity,minqty:data.minimumOrderQuantity,maxqty:data.maximumOrderQuantity}}
                    ></QuantityInfoForm>
                    </Panel>
                }
                {activeIndex === 2 && <Panel>

                    {
                        priceLists.map((el, idx) => {
                            return (
                                <PriceListForm
                                    name={el.priceListName!}
                                    key={idx}
                                    onSavePriceList={(data) => {
                                        const idx = array.findIndex((el) => el.priceListId === data.priceListId)
                                        const newArray = [...array];
                                        if (idx >= 0) {
                                            newArray[idx] = data;
                                        }
                                        else
                                            newArray.push(data);
                                        setArray(newArray);
                                    }}
                                    ref1={idx === 0 ? defaultRef : creditRef}
                                    mode={idx === 0 ? defaultMode : creditMode}
                                    storeProductId={id ? +id : -1}
                                    priceListId={el.priceListId}
                                    price={data.price}
                                    loading={loading}
                                    setMode={(mode) => idx === 0 ? setDefaultMode(mode) : setCreditMode(mode)}
                                    priceScheme={array.filter(x => x.priceListId === el.priceListId).at(0)!}
                                ></PriceListForm>
                            )
                        })
                    }
                </Panel >}
            </div>

        </React.Fragment >
    );
};
