import React, { FC, useMemo, useRef, useState } from 'react'
import { TawreedTable, TawreedTableProps } from '../../../../../common/components/table';
import { ImportHelperService, ImportTaskDto } from '../../domain';
import { CssUtils } from "../../../../../helpers/css";
import { TawreedTableFilters } from '../../../../../common/components/table/filters';
import { GlobalContext } from '../../../../../context';
import { Tag } from 'primereact/tag';
import { TawreedAction, TawreedActionName } from '../../../../../common/actions';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { tawreedTranslationService } from '../../../../../common/translations';
import { Routes } from '../../../../../router';
import { useHistory } from 'react-router-dom';
import { IStoreProductRawStats, IimportHelperStatus } from '../../../../constants';
import { ImportTaskDialog } from './upload-task';
import { endpoints, getBaseUrl } from '../../../../../constants/endpoints';
import { SplitButton } from 'primereact/splitbutton';
import { Menu } from 'primereact/menu';
import { JObject } from '../../../../../common/data/models';
import { MenuItem } from 'primereact/menuitem';
import './helper.scss'
export const ImportHelperTable: FC = (_props) => {

    const service: ImportHelperService = useMemo(() => new ImportHelperService(), []);
    const [reload, setReload] = useState(false);
    const { auth: { user }, constants: { constants } } = React.useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    let itemOptions: JObject | undefined = undefined;
    const [importDialogVisible, setImportDialogVisible] = useState(false);
    const menuRef = useRef<Menu>(null);
    const onImport = (): Promise<void> => {
        return new Promise((resolve) => {
            setImportDialogVisible(true);
            resolve();
        });
    }


    const restart = (data?: ImportTaskDto) => {
        setLoading(true);
        return service.restartTask(data!.importTaskId!).then(() => { setReload(true); setLoading(false) }).catch(err => console.error(err));
    }
    const downloadResult = (data?: ImportTaskDto) => {
        setLoading(true);
        return service.downloadResult(data!.importTaskId!, data?.type).then(() => { setReload(true); setLoading(false) }).catch(err => console.error(err));
    }
    const onDetails = (sender?: ImportTaskDto): Promise<void> => {
        return new Promise((resolve) => {
            if (sender?.status === IimportHelperStatus.COMPLETED)
                history.push(Routes.ImportHelperDetails.replace(':id', sender!.importTaskId!.toString()));
            resolve();
        })
    };
    const getItems = (status: string) => {
        if (status === IimportHelperStatus.COMPLETED) {
            return [
                {
                    label: tawreedTranslationService.translate("lbl_download_original_file"),
                    command: (e: any) => {
                        window.open(`${process.env.REACT_APP_S3_BUCKET}/${itemOptions?.fileId}`, "_blank");
                    }
                },
                {
                    label: tawreedTranslationService.translate("lbl_downlad_confirmed_result"),
                    command: (e: any) => {
                        downloadResult({ ...itemOptions, type: "CONFIRMED" });
                    }
                },
                {
                    label: tawreedTranslationService.translate("lbl_downlad_unconfirmed_result"),
                    command: (e: any) => {
                        downloadResult({ ...itemOptions, type: "UNCONFIRMED" });
                    }
                },
                {
                    label: tawreedTranslationService.translate("lbl_downlad_invalid_result"),
                    command: (e: any) => {
                        downloadResult({ ...itemOptions, type: "INVALID" });
                    }
                },
                {
                    label: tawreedTranslationService.translate("lbl_refresh"),

                    command: (e: any) => {
                        restart(itemOptions);
                    }
                },
            ]
        }
        else if (status === IimportHelperStatus.PENDING || status === IimportHelperStatus.NOT_STARTED) {
            return [
                {
                    label: tawreedTranslationService.translate("lbl_download_original_file"),
                    command: (e: any) => {
                        window.open(`${process.env.REACT_APP_S3_BUCKET}/${itemOptions?.fileId}`, "_blank");
                    }
                },
            ]
        }
        else if (status === IimportHelperStatus.FAILED) {
            return [
                {
                    label: tawreedTranslationService.translate("lbl_download_original_file"),
                    command: (e: any) => {
                        window.open(`${process.env.REACT_APP_S3_BUCKET}/${itemOptions?.fileId}`, "_blank");
                    }

                }, {
                    label: tawreedTranslationService.translate("lbl_refresh"),

                    command: (e: any) => {
                        restart(itemOptions);
                    }
                },
            ]

        }
    }



    const save = () => {
    };


    const tawreed: TawreedTableProps<ImportTaskDto> = {
        header: true,
        pagination: true,
        reload: reload,
        outLoader: loading,
        dataKey: 'importTaskId',
        name: 'tbl.import.helper',
        title: 'lbl_import_helper',
        ds: {
            mode: 'lazy',
            onSearch: (q) => service.search(q),
        },
        columns: [
            {
                field: "importTaskId",
                header: "lbl_import_task_id",
                sortable: true,
                filter: true,
                style: CssUtils.width('23%'),
            },
            {
                field: "storeName",
                header: "lbl_store_products_store_name",
                sortable: true,
                filter: true,
                style: CssUtils.width('23%'),
            },
            {
                field: "status",
                header: "lbl_sales_orders_status",
                sortable: true,
                sortField: "status",
                filter: true,
                filterField: 'status',
                ...TawreedTableFilters.dropdown.dropdown('status', constants?.importHelperStatus ?? [], 'value', 'label', 'in', (label: string) => <Tag rounded severity={'success'} style={{ width: '6rem' }} value={label} />),
                style: CssUtils.width('23%'),
            },
            {
                field: "duration",
                header: "lbl_import_task_duration",
                body: (options: any) => {
                    return (
                        <React.Fragment>
                            <p>{options.duration ? (options.duration / 60).toFixed(0) + "m:" + options.duration % 60 + "s" : "-"}</p>
                        </React.Fragment>)
                },
                style: CssUtils.width('20%')
            },
            {
                field: "test",  
                header: "lbl_actions",
                style: CssUtils.width('10%'),
                body: (options: any) => {
                    return (
                        <div className='inline-flex align-items-center justify-content-center'>
                             <Button
                            type="button"
                            style={{height: "35px" }}
                            icon={PrimeIcons.EYE}
                            className="p-button-success p-button-text"
                            tooltip={tawreedTranslationService.translate('lbl_info')}
                            onClick={() => {
                                history.push(Routes.ImportHelperDetails.replace(':id', options!.importTaskId.toString()));
                                
                            }} />
                            <SplitButton menuButtonClassName='p-splitbutton-menubutton' buttonClassName='hideButth' label={""}  dropdownIcon="pi pi-ellipsis-v" icon="" onShow={() => { itemOptions = options }} model={getItems(options.status)} />
                        </div>)
                }
            },

        ],

        toolbar: {
            actions: [
                // new TawreedAction(TawreedActionName.StoreProductsDownloadTemplate, 'stateless', 'lbl_store_products_download_template', PrimeIcons.UPLOAD, onDownloadTemplate),
            ],
        },
        canImport: new TawreedAction(TawreedActionName.StoreProductsStageImport, 'stateless', 'lbl_store_products_import', PrimeIcons.UPLOAD, onImport),

        // row: {
        //     onClick: new TawreedAction(TawreedActionName.ImpoerHelperDetails, 'statefull', 'lbl_info', PrimeIcons.INFO, onDetails, 'p-button-secondary', false),
        // },

        filter: {
            filterDisplay: 'menu',
            initial: {
                'status': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'in',
                        },
                    ]
                },
                'storeName': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'importTaskId': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
            },
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'importTaskId',
                sortOrder: -1,
                multiSortMeta: undefined,
            },
        },
        selection: {
            mode: 'single',
        },
    }

    return (
        <React.Fragment>
            <TawreedTable  {...tawreed} />
            <ImportTaskDialog
                uploadUrl={getBaseUrl() + endpoints.IMPORT_TAKS}
                accept=".xls,.xlsx"
                visible={importDialogVisible}
                hide={() => {
                    setImportDialogVisible(false);
                    setReload(true);
                }}
                isStore={user?.roleCode === 'STORE_ADMIN' ? true : false}
                partnerId={user?.partnerId!}

            />
        </React.Fragment>
    );
}

