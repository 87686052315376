import React, { FC, useMemo, useState } from "react";
import { PrimeIcons } from "primereact/api";
import { Panel } from "primereact/panel";
import { useHistory, useParams } from "react-router-dom";

import { TawreedAction, TawreedActionName } from "../../../../common/actions";
import { TawreedCalendar, TawreedDropdown, TawreedForm, TawreedFormField, TawreedFormMode, TawreedFormSubmitAction, TawreedFormSubmitCallback, TawreedFormSubmitProps, TawreedInputCustom, TawreedInputFile, TawreedInputNumber, TawreedInputSwitch, TawreedInputText, TawreedMenuDropdown, TawreedNavLink, TawreedSitesDropdown } from "../../../../common/components/form";
import { tawreedTranslationService } from "../../../../common/translations";
import { Routes } from "../../../../router";
import { StatesDropdown } from "../../../states";
import { PartnerStatus, PartnerType } from "../../data";
import { CommissionDto, CommissionService, MappingDto, PartnerDto, PartnersService, StoreProductCategoryDto, StoreZoneDto } from "../../domain";
import { PartnerBusinessTypeDropdown } from "./partner-business-type-dropdown";
import { PriceListServcie } from "../../../catalog/store-products/domain/services/price-list.service";
import { priceListDto } from "../../../catalog/store-products/domain";
import { ZonesDropdown, ZonesMultiSelect } from "../../../zones";
import { CategoriesMultiSelect } from "../../../catalog/products/presentaion/components";
import { toastService } from "../../../notifications/domain";
import { PartnerFiles } from "./partner-files";

import './image-card.scss';
import { GlobalContext } from "../../../../context";
import { CitiesDropdown } from "../../../cities";
import { PriceListForm } from "./price-list-from";

export interface PartnerFormProps {

    type: PartnerType;  
}

export const PartnerForm: FC<PartnerFormProps> = ({ type }) => {
    // di
    const { id } = useParams<{ id: string | undefined }>();
    const history = useHistory();
    const service: PartnersService = useMemo(() => new PartnersService(), []);
    const priceListService: PriceListServcie = useMemo(() => new PriceListServcie(), []);
    const commissionService: CommissionService = useMemo(() => new CommissionService(), []);
    const [priceLists, setPriceLists] = useState<Array<priceListDto>>([]);
    const [reload, setReload] = useState<Boolean>(false);
    const [commissions, setCommissions] = useState<Array<CommissionDto>>([]);
    const [mappingItems, setMappingItems] = useState<Array<MappingDto>>([]);

    const [loading, setLoading] = useState<boolean>(false);
    const [mode, setMode] = React.useState<TawreedFormMode>('Create');
    const [data, setData] = useState<PartnerDto>({});
    const [zoneList, setZoneList] = useState<Array<StoreZoneDto>>([]);
    const [storeProductCategoryList, setStoreProductCategoryList] = useState<Array<StoreProductCategoryDto>>([]);

    const [actions, setActions] = useState<TawreedAction[]>([]);
    const { constants, auth: { user } } = React.useContext(GlobalContext);
    const [stateId, setStateId] = useState<number>();

    let disabeld = true;
    React.useEffect(() => {
        let mounted = true;
        if (id) {
            if (mounted) {
                setMode('Edit');
                setLoading(true);
            }
            priceListService.getAll().then((res) => {
                setPriceLists(res);
            });
            if (type == PartnerType.Store) {
                service.getStoreMapping(Number.parseInt(id))
                    .then(res2 => {
                        setMappingItems(res2);
                    });
            }
            service.getDetails(Number.parseInt(id))
                .then(res => {
                    setStateId(res.address?.stateId);
                    if (mounted) {
                        setLoading(false);
                        setData(res);
                        setZoneList(res.zoneList ?? []);
                        setStoreProductCategoryList(res.storeProductCategoryList ?? [])
                    }
                })
                .catch(() => {
                    if (mounted) {
                        setLoading(false);
                    }
                })
        }
        return () => {
            mounted = false;
        };
        // eslint-disable-next-line
    }, [id, service, priceListService, reload]);

    const onCreateOrSave = async (data: PartnerDto, cb: TawreedFormSubmitCallback<PartnerDto>): Promise<void> => {

        setLoading(true);
        return service.createOrUpdate(type, { ...data})
            .then(async (res) => {
                if (!data.partnerId) {
                    
                    history.replace(Routes.PartnersStoresDetails.replace(':id', res.partnerId!.toString()));
                }
                else
                    setReload(!reload);
                setLoading(false);
                setData(res);
                setZoneList(res.zoneList ?? []);
                setStoreProductCategoryList(res.storeProductCategoryList ?? [])
                cb(res);
            })
            .catch(err => {
                setLoading(false);
                cb(err);
            });
    }

    const onSubmit: TawreedFormSubmitProps<PartnerDto> = {
        showButton: true,
        resetOnSubmit: false,
        onSubmit: new TawreedFormSubmitAction<PartnerDto>(
           TawreedActionName.StorePartnerSubmit, 'lbl_save', PrimeIcons.SAVE, onCreateOrSave, 'p-button-outlined'),
    };

    const changeComission = (data: CommissionDto, isInit: boolean) => {
        
    }
    const toZones = (data: any) => {
        console.log(data);
        return data?.map((el: any) => {
            return {
                stateId: undefined,
                zoneId: el.zoneId,
                zoneName: el.zoneName,
                zoneNameEn: "",
            }
        }) ?? [];
    }

    return (
        <React.Fragment>
            <TawreedForm title={'lbl_partner_' + type}
                dataKey="partnerId" data={data}
                submit={onSubmit}
                loading={loading} mode={mode}
                actions={actions} className="grid form-grid">
                <Panel header={tawreedTranslationService.translate('lbl_general_information')} className="col-12">
                    <div className="grid">
                        {
                            type === PartnerType.Store &&
                            <div className="col-12">
                                <div className="grid">
                                    <TawreedFormField name="logoContent" className="field col-12 md:col-3" title="lbl_partners_logo">
                                        <TawreedInputFile disabled={false} render="form" name="logoContent" accept="image" />
                                    </TawreedFormField>
                                </div>
                            </div>
                        }

                        <TawreedFormField name="businessType" className="field col-12 md:col-6" title="lbl_partners_business_type">
                            <PartnerBusinessTypeDropdown disabled={disabeld} partnerType={type} name="businessType" render="form" />
                        </TawreedFormField>
                        <TawreedFormField hide={disabeld} name="active" title="lbl_product_active" className="field-checkbox col-12 md:col-6" flip>
                            <TawreedInputSwitch name="active" render="form" />
                        </TawreedFormField>
                        <TawreedFormField name="partnerName" className="field col-12 md:col-6" title="lbl_partners_partner_name">
                            <TawreedInputText disabled={disabeld} name="partnerName" render="form" />
                        </TawreedFormField>
                        
                         <React.Fragment>

                                <TawreedFormField name="orderMin" className="field col-12 md:col-6" title="lbl_partners_order_min">
                                    <TawreedInputNumber disabled={false} name="orderMin" render="form" mode="decimal" />
                                </TawreedFormField>

                            </React.Fragment>
                    </div>
                </Panel>


                <Panel header={tawreedTranslationService.translate('lbl_partners_address_information')} className="col-12">
                    <div className="grid">
                        <TawreedFormField name="address.stateId" className="field col-12 md:col-12" title="lbl_partners_address_state_id">
                            <StatesDropdown onChange={(e) => { setStateId(e.value); }} disabled={disabeld} name="address.stateId" render="form" />
                        </TawreedFormField>

                        <TawreedFormField name="address.cityId" className="field col-12 md:col-12" title="lbl_partners_address_city_id">
                            <CitiesDropdown parentId={stateId} disabled={disabeld} name="address.cityId" render="form"
                                 />
                        </TawreedFormField>

                        <TawreedFormField name="address.street" className="field col-12" title="lbl_partners_address_street">
                            <TawreedInputText disabled={disabeld} name="address.street" render="form" />
                        </TawreedFormField>
                        <TawreedFormField name="address.latitude" className="field col-12 md:col-6" title="lbl_partners_address_latitude">
                            <TawreedInputNumber disabled={disabeld} name="address.latitude" mode="decimal" render="form" maxFractionDigits={12} minFractionDigits={0} />
                        </TawreedFormField>
                        <TawreedFormField name="address.longitude" className="field col-12 md:col-6" title="lbl_partners_address_longitude">
                            <TawreedInputNumber disabled={disabeld} name="address.longitude" mode="decimal" render="form" maxFractionDigits={12} minFractionDigits={0} />
                        </TawreedFormField>

                        {
                            <TawreedFormField name="address.location" className="field col-12" title="">
                                <TawreedNavLink disabled={disabeld} render="form" name="address.location">
                                    {tawreedTranslationService.translate('lbl_partners_address_location')}
                                </TawreedNavLink>
                            </TawreedFormField>
                        }
                    </div>
                </Panel>

                {
                    user?.roleCode === "STORE_ADMIN" &&
                    <Panel header={tawreedTranslationService.translate('lbl_store_zones_name')} className="col-12">
                        <TawreedFormField name="zone" className="field col-12" title="lbl_zones_name">
                            <ZonesMultiSelect disabled={disabeld} name="zone" render="standalone" value={toZones(zoneList)} onChange={(e: any) => setZoneList(e.value)} />
                        </TawreedFormField>
                    </Panel>
                }
                
                {
                    <Panel header={tawreedTranslationService.translate('lbl_partners_contact_information')} className="col-12">
                        <div className="grid">
                            <TawreedFormField name="contact.contactName" className="field col-12 md:col-6" title="lbl_partners_contact_name">
                                <TawreedInputText name="contact.contactName" render="form" disabled readOnly />
                            </TawreedFormField>
                            <TawreedFormField name="contact.mobileNo" className="field col-12 md:col-6" title="lbl_partners_contact_mobile">
                                <TawreedInputText name="contact.mobileNo" render="form" disabled readOnly />
                            </TawreedFormField>
                            <TawreedFormField name="contact.email" className="field col-12 md:col-12" title="lbl_partners_contact_email">
                                <TawreedInputText name="contact.email" render="form" disabled readOnly />
                            </TawreedFormField>
                        </div>
                    </Panel>
                }
            </TawreedForm>
            {
                   user?.roleCode === "STORE_ADMIN"
                &&
                <Panel header={tawreedTranslationService.translate('lbl_commission_list')}>
                    {priceLists.map((el, idx) => {
                        return (
                            <PriceListForm
                                disabeld={disabeld}
                                key={idx}
                                name={el.priceListName!}
                                partnerId={id ? +id : -1}
                                priceListId={el.priceListId}
                                onChange={changeComission}
                            ></PriceListForm>
                        )
                    })}
                </Panel>
            }
        </React.Fragment>

    );
}
