import React, { useState } from "react";
import { TawreedTable, TawreedTableColumnProps, TawreedTableProps } from "../../../../../common/components/table";
import { OrderItemDto } from "../../domain";
import { TawreedTableEditor } from "../../../../../common/components/table/editors";
import { TawreedTableFormatters } from "../../../../../common/components/table/formatters";
import { tawreedTranslationService } from "../../../../../common/translations";
import { CssUtils } from "../../../../../helpers/css";
import { TawreedActionName } from "../../../../../common/actions";
import { AuthUtils } from "../../../../auth/domain";
import { GlobalContext } from "../../../../../context";
import { IBonusType } from "../../../../constants";
import { OrderItemUpdateDialog } from "./order-form/order-item-update-dialog";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";

export type OrderFormItemsProps = {
    // controls
    className: string;
    disabled: boolean;
    // data
    showEdit?: boolean;
    items?: Array<OrderItemDto>;
    showNote: boolean
    // actions
    onSave: (item: OrderItemDto) => Promise<boolean>;

    returned: boolean | undefined;
};

export const OrderFormItems: React.FC<OrderFormItemsProps> = (props) => {

    const { constants: { constants } } = React.useContext(GlobalContext);
    const [updateItemDialog,setUpdateItemDialog] = useState<boolean>(false);

    const [selectedItem, setSelectedItem] = useState<OrderItemDto>();
    let columns: TawreedTableColumnProps[] =props.returned ?
    [
        {
            dataType: 'text',
            field: "productName",
            header: "lbl_sales_orders_o_info_items_product_name",
            style: CssUtils.width('20%'),
        },
        {
            dataType: 'numeric',
            field: "quantity",
            header: "lbl_sales_orders_o_info_items_quantity",
            body: props.showEdit ? TawreedTableEditor.numeric('int', 'quantityOrdered', 0, !AuthUtils.current().authorized(TawreedActionName.OrdersEditQuantity)) : undefined,
            style: CssUtils.width('20%'),
        },
        {
            dataType: 'numeric',
            field: "retailPrice",
            header: "lbl_sales_orders_o_info_items_retail_price",
            style: CssUtils.width('20%'),
        },
        {
            dataType: 'numeric',
            field: "discountPercent",
            header: "lbl_sales_orders_o_info_items_discount_percent",
            style: CssUtils.width('20%'),
        },
        {
            field: "itemTotal",
            header: "lbl_sales_orders_o_info_items_item_total",
            ...TawreedTableFormatters.decimal("itemTotal", CssUtils.width('20%')),
        },
    ]
    :
    [
        {
            dataType: 'text',
            field: "productName",
            header: "lbl_sales_orders_o_info_items_product_name",
            style: CssUtils.width('25%'),
        },
        {
            dataType: 'numeric',
            field: "quantityOrdered",
            header: "lbl_sales_orders_o_info_items_quantity_ordered",
            style: CssUtils.width('8%'),
        },
        {
            dataType: 'numeric',
            field: "quantity",
            header: "lbl_sales_orders_o_info_items_quantity",
            style: CssUtils.width('8%'),
        },
        {
            field: "retailPrice",
            header: "lbl_sales_cart_store_cart_items_retail_price",
            ...TawreedTableFormatters.decimal("retailPrice"),
            style: CssUtils.width('10%'),
            className: CssUtils.className.field.amount,
        },
        {
            field: "discountValue",
            header: "lbl_base_discount",
            ...TawreedTableFormatters.currencyOrPercentageDepend("discountValue", 'discountType', constants?.info?.currencyCode ?? ''),
            style: CssUtils.width('10%'),
            className: CssUtils.className.field.amount,
        },
        {
            dataType: 'numeric',
            field: "discountBonus",
            header: "lbl_additional_discount",
            style: CssUtils.width('10%'),
        },
        {
            dataType: 'numeric',
            field: "bonus",
            header: "lbl_store_products_bonus",
            style: CssUtils.width('8%'),
        },
        {
            field: "tax",
            header: "lbl_salesordersitem_totaltax",
            ...TawreedTableFormatters.percentage("storeProductTax"),
            style: CssUtils.width('8%'),
            className: CssUtils.className.field.amount,
        },
        {
            field: "itemTotal",
            header: "lbl_sales_orders_o_info_items_item_total",
            ...TawreedTableFormatters.decimal("itemTotal"),
            style: CssUtils.width('20%'),
            className: CssUtils.className.field.amount,
        },
    ];
    if (props.showEdit){
        columns = [...columns, {
            field: "delete",
            className: CssUtils.className.field.amount,
            body: (options: any) => {
                return (
                    <Button type="button" icon={PrimeIcons.PENCIL} onClick={() => 
                        {
                            setSelectedItem(options);
                            setUpdateItemDialog(true);
                        }
                    } disabled={props.disabled} className="p-button-rounded p-button-text p-button-danger" />
                )
            }
        }];
    }
    const tawreed: TawreedTableProps = {
        ds: {
            mode: 'basic',
            data: props.items ?? [],
        },
        name: 'tbl.sales.orders.order.items',
        dataKey: 'orderItemId',
        columns: columns,
        editable: props.showEdit ? { onEditComplete: (newRowData, _field, _value) => props.onSave(newRowData) } : undefined,
        // header: {
        //     left: {
        //         search: false,
        //         custom: (
        //             <div className="flex-column">
        //                 <span style={{fontSize:'1.5rem', display:'block'}}>
        //                     {tawreedTranslationService.translate('lbl_sales_orders_o_info_items')}
        //                 </span>
        //                 {props.showNote && <span style={{ fontSize: '13px', fontWeight: 'normal', padding: '4px' }}>
        //                     {tawreedTranslationService.translate('lbl_placed_note')}
        //                 </span>}
        //             </div>

        //         )
        //     }
        // }
    };

    return (<div className={props.className}>
        <TawreedTable {...tawreed} />
        <OrderItemUpdateDialog
                onHide={() => { setUpdateItemDialog(false) }}
                onSave={(data) => {
                   props.onSave(data)
                }}
                item={selectedItem!}
                
                visible={updateItemDialog} />
    </div>);
}
