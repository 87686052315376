import React, { FC } from "react";
import { PartnerType } from "../../data";
import { PartnerForm } from "../components/partner-form";



/**
 * Tawreed Store Form Page
 * @returns 
 */
export const StoreFormPage: FC = () => (<PartnerForm type={PartnerType.Store} />);
