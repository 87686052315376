import React, { FC } from 'react'
import { UserForm } from '../components';



/**
 * Tawreed  User Form Page
 * @returns 
 */
export const UserFormPage: FC = () => (<UserForm />);

