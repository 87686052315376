import React from 'react'
import { PrimeIcons } from 'primereact/api';
import { useHistory } from 'react-router-dom';
import { TawreedAction, TawreedActionName } from '../../../../../common/actions';
import { TawreedTable, TawreedTableProps } from '../../../../../common/components/table';
import { Routes } from '../../../../../router';
import { CustomerCartDto, CartService } from '../../domain';
import { CssUtils } from "../../../../../helpers/css";
import { GlobalContext } from "../../../../../context";
import { TawreedTableFormatters } from '../../../../../common/components/table/formatters';
import { Button } from 'primereact/button';
import { tawreedTranslationService } from '../../../../../common/translations';

export const CartsTable: React.FC = () => {

    const history = useHistory();
    const { auth: { user } } = React.useContext(GlobalContext);
    const service: CartService = React.useMemo(() => new CartService(), []);

    const onNew = (): Promise<void> => {
        return new Promise((resolve) => {
            history.push(Routes.SalesCartsNew);
            resolve();
        })
    };

    const onForm = React.useCallback(
        (sender?: CustomerCartDto): Promise<void> => {
            return new Promise((resolve) => {
                let route = Routes.SalesCartsDetails.replace(':id', sender!.customer!.partnerId!.toString());
                if (sender?.priceListId)
                    route += `?priceListId=${sender!.priceListId}`;
                if (sender?.salesOfferId)
                    route += `&&salesOfferId=${sender!.salesOfferId}`;
                
                history.push(route);
                resolve();
            });
        },
        [history]);


    const tawreed: TawreedTableProps = {
        header: true,
        pagination: true,
        dataKey: 'customer',
        name: 'tbl.sales.carts',
        title: 'lbl_sales_carts',
        ds: {
            mode: 'lazy',
            onSearch: (req) => service.search(req),
        },
        columns: [
            {
                dataType: 'text',
                field: "customer.partnerName",
                header: "lbl_sales_cart_customer",
                sortable: true,
                sortField: "customer",
                style: CssUtils.width('30%'),
            },
            {
                field: "priceListName",
                header: "lbl_price_scheme_price_list_name",
                style: CssUtils.width('20%'),
            },
            {
                field: "salesOfferTitle",
                header: "lbl_sales_offer_list_title",
                style: CssUtils.width('15%'),
            },
            {
                field: "orderTotals",
                header: "lbl_sales_cart_order_totals",
                sortable: true,
                ...TawreedTableFormatters.decimal('orderTotals', CssUtils.width('15%')),
            },
            
            {
                field: "actions",
                header: "lbl_actions",
                style: CssUtils.width('10%'),
                body: (options: any) => {
                    return <React.Fragment>
                        <Button
                            type="button"
                            style={{height: "35px" }}
                            icon={PrimeIcons.EYE}
                            className="p-button-success p-button-outlined"
                            tooltip={tawreedTranslationService.translate('lbl_info')}
                            onClick={() => {
                                let route = Routes.SalesCartsDetails.replace(':id', options!.customer!.partnerId!.toString());
                                if (options?.priceListId)
                                    route += `?priceListId=${options!.priceListId}`;
                                if (options?.salesOfferId)
                                    route += `&&salesOfferId=${options!.salesOfferId}`;
                                
                                history.push(route);
                                
                            }} />
                        
                    </React.Fragment>
                },
            }
        ],
        toolbar: {
            actions: [
                new TawreedAction(TawreedActionName.CartsCreate, 'stateless', 'lbl_new', PrimeIcons.PLUS_CIRCLE, onNew),
            ],
        },
        row: {
            //onClick: new TawreedAction(TawreedActionName.CartsDetails, 'statefull', 'lbl_info', PrimeIcons.INFO, onForm),
        },
        selection: {
            mode: 'single',
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'customer',
                sortOrder: -1,
                multiSortMeta: undefined,
            }
        }
    }

    return (<TawreedTable  {...tawreed} />);
}
