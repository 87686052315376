import React, { useEffect, useState } from "react";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { tawreedTranslationService } from "../../../../../common/translations";
import { Dialog } from "primereact/dialog";
import { TawreedCheckbox, TawreedFormField, TawreedInputNumber } from "../../../../../common/components/form";

import { CartService, CustomerStoreCartItemDto } from "../../domain";
import { Panel } from "primereact/panel";
import { IBonusOperations, IBonusType } from "../../../../constants";
import { CssUtils } from "../../../../../helpers/css";
import { ObjectUtils } from "../../../../../helpers/object";
import { Card } from "primereact/card";
import { AuthUtils } from "../../../../auth/domain";
import { StoreProductDto } from "../../../../catalog/store-products/domain";
import { DateUtils } from "../../../../../helpers/date";


export interface CartItemProps {
    item:CustomerStoreCartItemDto,
    onDeleteItem: (item: CustomerStoreCartItemDto) => void;
    onSave: (item: CustomerStoreCartItemDto) => void;
    visible: boolean;
    onHide: ()=>void;
    storeProduct: StoreProductDto;
}

export const CartItemUpdateDialog: React.FC<CartItemProps> = (props) => {

    const [quantity,setQuantity] = useState<number>(props.item ? props.item.quantity! :  0);

    const [details, setDetails] = useState<any>({})
    const [loader, setLoader] = useState<boolean>(false);
    const [bonusType, setBonusType] = useState<string|undefined>(undefined);
    const [imageUrl, setImageUrl] = useState<string|undefined>(undefined);
    const service: CartService = React.useMemo(() => new CartService(), []);

    const DialogFooter = () => {
        return (
            <div>
                <Button className="p-button-text p-button-text" label={tawreedTranslationService.translate('lbl_close')} icon={PrimeIcons.TIMES} onClick={() => onHide(false)} />
                <Button className="p-button-text p-button-text" label={tawreedTranslationService.translate('lbl_okay')}
                    icon={PrimeIcons.CHECK}

                    disabled={!quantity || quantity> props.item.availableQuantity!}
                    onClick={() => { props.onSave({...props.item, quantity:quantity, bonusType:bonusType}); props.onHide()  }}
                />
                <Button className="p-button-text p-button-danger p-button-text" label={tawreedTranslationService.translate('lbl_delete')}
                    icon={PrimeIcons.TRASH}
                    onClick={() => {props.onDeleteItem(props.item); props.onHide() }}
                />
            </div>
        );
    }

    useEffect(()=>{
        if(props.item)
            {
                setQuantity(props.item.quantity!);
                setBonusType(props.item.bonusType!);
        setImageUrl(props.storeProduct?.imageUrl);
            }
        else{
            setQuantity(0);
            setBonusType(IBonusType.EXTRA_ITEMS);
        }
    },[props.item]);

    useEffect(() => {
        let time = setTimeout(() => {
            getDetails();
        }, 500)

        return () => clearTimeout(time);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quantity])

    const getDetails = () => {
        setLoader(true);
        if (props.storeProduct?.storeProductId) {

            service.getItemDetails({ quantity: quantity, storeProductId: props.storeProduct?.storeProductId, priceListId: props.storeProduct?.priceListId }).then(res => {
                setDetails(res)
                setLoader(false);
                //changeQuantity(quantity, bonusType);
            }).catch((res)=>{
                setLoader(false);
            });
        }
    }

    const onHide = (res: boolean) => {
        if (props.onHide) {
            props.onHide();
        }
    }
    const getBox = (discount: string) => {
        if (discount === 'fixed')
            return "JOD"
        if (discount === 'percentage')
            return "%"

        return "%+"
    }
    const getSellingPrice = () => {

        let discountType = 'fixed';
        if (props.storeProduct?.discountType === 'percentage')
            discountType = 'percentage';
        if (props.storeProduct?.discountType === 'bonus')
            discountType = 'bonus'
        let discountValue = 0;
        if (props.storeProduct?.discountValue)
            discountValue = props.storeProduct?.discountValue;
        let sellingPrice = props.storeProduct?.price!;
        sellingPrice -= (discountType === 'fixed' ? discountValue : (props.storeProduct?.price! * discountValue / 100));
        if (discountType === 'bonus')
            sellingPrice = props.storeProduct?.price! - (props.storeProduct?.price! * (discountValue / (discountValue + 100)))
        return sellingPrice;
    }
    const header = (type: IBonusType) => {
        return (
            <div className='inline-flex align-items-center'>

                {
                    <TawreedCheckbox render='standalone' checked={bonusType === type}
                        onChange={
                            () => { setBonusType(type);}// changeQuantity(quantity, type) }
                        }></TawreedCheckbox>

                }
                <span className='ml-2'>{type === IBonusType.EXTRA_ITEMS ?
                    tawreedTranslationService.translate('lbl_sales_cart_summary_bonus') :
                    tawreedTranslationService.translate('lbl_sales_cart_summary_discount')
                }</span>
            </div>);
    }

    return (
        <Dialog maximizable visible={props.visible} onHide={() => onHide(false)}
            header={tawreedTranslationService.translate('lbl_checkout_form')} footer={<DialogFooter />}
            position="top" breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ maxWidth: '512px', minWidth: '55%' }} draggable={false} closable={true} focusOnShow closeOnEscape={true}>
<div className='grid'>
                <div className="col-6">
                    <div className='col-12'>
                            <React.Fragment>
                                <TawreedFormField name="quantity" title="lbl_store_products_bonus_qty" className="field col-12 md:col-12">
                                    <span>
                                        {loader && <i className="pi pi-spinner pi-spin ml-2"></i>}
                                        <TawreedInputNumber
                                            autoFocus allowEmpty={false} min={1} max={1000} value={quantity} name="quantity" rules={{ required: "msg_field_is_required" }} render="form"
                                            onChange={(e) => {
                                                console.log(e);
                                                if (!(e && e.value && (e.value > 1000 || e.value <= 0)))
                                                    setQuantity(e.value || 0);
                                                else
                                                    setQuantity(1);
                                            }}
                                        />

                                    </span>

                                </TawreedFormField>
                            </React.Fragment>

                    </div>
                    <div className='col-12'>
                        <Panel style={{ cursor: 'pointer' }} header={header(IBonusType.EXTRA_ITEMS)}>
                            <div className="grid" >

                                <div className="col-6">
                                    {tawreedTranslationService.translate('lbl_sub_total')}
                                </div>
                                <div className="col-6" style={CssUtils.align('right')}>
                                    {'JOD ' + ObjectUtils.toLocalString(details.sellingPrice * details.quantity)}
                                </div>
                                <div className="col-6">
                                    {tawreedTranslationService.translate('lbl_total_tax')}
                                </div>
                                <div className="col-6" style={CssUtils.align('right')}>
                                    {'JOD ' + ObjectUtils.toLocalString(details.itemTotalTax)}
                                </div>
                                <div className="col-6">
                                    {tawreedTranslationService.translate('lbl_bonus')}
                                </div>
                                <div className="col-6" style={CssUtils.align('right')}>
                                    {details?.availableBonus?.some((el: any) => el.bonusType === IBonusType.EXTRA_ITEMS) ? details?.availableBonus?.find((el: any) => el.bonusType === IBonusType.EXTRA_ITEMS).bonus : 0}
                                </div>
                                <div className="col-12"><hr></hr></div>
                                <div className="col-6">
                                    {tawreedTranslationService.translate('lbl_total')}
                                </div>
                                <div className="col-6" style={CssUtils.align('right')}>
                                    {'JOD ' + ObjectUtils.toLocalString(details.sellingPrice * details.quantity + details.itemTotalTax)}
                                </div>

                            </div>
                        </Panel>
                    </div>
                    <div className='col-12'>
                        <Panel style={{ cursor: 'pointer' }} header={header(IBonusType.DISCOUNT)}>
                            <div className="grid" >

                                <div className="col-6">
                                    {tawreedTranslationService.translate('lbl_sub_total')}
                                </div>
                                <div className="col-6" style={CssUtils.align('right')}>
                                    {'JOD ' + ObjectUtils.toLocalString(details.sellingPrice * details.quantity)}
                                </div>
                                <div className="col-6">
                                    {tawreedTranslationService.translate('lbl_total_tax')}
                                </div>
                                <div className="col-6" style={CssUtils.align('right')}>
                                    {'JOD ' + ObjectUtils.toLocalString(details.itemTotalTaxUsingOffers)}
                                </div>
                                <div className="col-6">
                                    {tawreedTranslationService.translate('lbl_additional_discount')}
                                </div>
                                <div className="col-6" style={CssUtils.align('right')}>
                                    {'JOD ' + ObjectUtils.toLocalString(details?.availableBonus?.some((el: any) => el.bonusType === IBonusType.DISCOUNT) ? details?.availableBonus?.find((el: any) => el.bonusType === IBonusType.DISCOUNT).bonus : 0)}
                                </div>
                                <div className="col-12"><hr></hr></div>
                                <div className="col-6">
                                    {tawreedTranslationService.translate('lbl_total')}
                                </div>
                                <div className="col-6" style={CssUtils.align('right')}>
                                    {'JOD ' + ObjectUtils.toLocalString(details.itemTotalAfterBonusDiscount)}
                                </div>

                            </div>
                        </Panel>
                    </div>
                </div>

                <div className='col-6'>

                    <div className="col-12 md:col-12 proudctName">
                        <Card>
                            <div className='grid'>
                                <div className='col-4'>
                                    {imageUrl &&
                                        <img onError={() => {
                                            let img = imageUrl.split("/products")[0] + "/categories/" + props.storeProduct?.categoryId?.toString() ?? "";
                                            if (imageUrl.indexOf("categories") === -1)
                                                setImageUrl(img)
                                        }} src={imageUrl} width={'100%'} height={'100%'} alt='' className='p-button-rounded' />

                                    }
                                </div>
                                <div className='col-8'>
                                    <h4>
                                        {AuthUtils.current().language === 'en' ? props.storeProduct?.titleEn : props.storeProduct?.titleAr}
                                    </h4>
                                    <p>
                                        {tawreedTranslationService.translate('lbl_store_product_expire_date') + ": " + (props.storeProduct?.expireDate && DateUtils.format(props.storeProduct?.expireDate, 'date'))}
                                    </p>
                                </div>
                            </div>
                        </Card>
                        <div className="grid mt-4">
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_price_scheme_price_list_name')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {props.storeProduct?.priceListName}
                            </div>
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_price')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {'JOD ' + ObjectUtils.toLocalString(props.storeProduct?.price)}
                            </div>
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_discount')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {ObjectUtils.toDiscount(props.storeProduct?.discountType!) + ObjectUtils.toLocalString(props.storeProduct?.discountValue)}
                            </div>
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_sellingPrice')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {'JOD ' + ObjectUtils.toLocalString(getSellingPrice())}
                            </div>
                            
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_quantity')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {props.storeProduct?.quantity ?? ""}
                            </div>

                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_min_order_qty')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {props.storeProduct?.minimumOrderQuantity ?? ""}
                            </div>


                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_max_order_qty')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {props.storeProduct?.maximumOrderQuantity ?? ""}
                            </div>

                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_tax')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {'% ' + ObjectUtils.toLocalString(props.storeProduct?.tax)}
                            </div>
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_bonus')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {
                                    props.storeProduct?.bonuses !== undefined &&
                                    props.storeProduct?.bonuses.length > 0 &&
                                    <React.Fragment>
                                        <div className="bonuses">
                                            {props.storeProduct?.bonuses.map((e) => {
                                                if (e.bonusType === IBonusType.EXTRA_ITEMS)
                                                    return <div className="bonuses-item">{e.quantity} + {e.bonus}</div>
                                                return <></>
                                            })
                                            }
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_Discounts')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {
                                    props.storeProduct?.bonuses !== undefined &&
                                    props.storeProduct?.bonuses.length > 0 &&
                                    <React.Fragment>
                                        <div className="bonuses">
                                            {props.storeProduct?.bonuses.map((e) => {
                                                if (e.bonusType === IBonusType.DISCOUNT)
                                                    return <div className="bonuses-item">{e.quantity}{e.bonusOperation === IBonusOperations.GREATER_THAN ? " > *" : " * "}  {e.bonus} {getBox(e.discountType!)}</div>
                                                return <></>
                                            })
                                            }
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Dialog>
    );
}
