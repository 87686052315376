import React from 'react'
import { TawreedTableFilters } from '.';
import { Tag } from 'primereact/tag';
import { tawreedTranslationService } from '../../../translations';
import { TawreedInputSwitch } from '../../form';

export const builQuantityActiveTawreedTableFormatter = (field: string, Statuses: any, callback: (id: number, value: boolean) => Promise<void>, field_id: string, quantity_field: string) => {
    const defaultStatus = [
        {
            value: true,
            label: tawreedTranslationService.translate("lbl_active")
        },
        {
            value: false,
            label: tawreedTranslationService.translate("lbl_not_active")
        }];
    return {
        ...TawreedTableFilters.dropdown.booleanWihField(field, Statuses ?? defaultStatus, [], 'value', 'label',
            (rowData: any, valid: boolean, id: number,quantity:string) => {
                let style = { background: valid ? '#C5FEC6' : '#FFA0A0', color: valid ? '#3CAB3E' : '#E80A0A' }
                var quantityValue = Number(quantity);
                let quantityStyle ={};
                let quantityStatus ="";
                
                if(quantity != undefined){
                    if(quantityValue ===0){
                        quantityStyle= {...quantityStyle,  background: '#FFA0A0', color: '#E80A0A'}
                        quantityStatus = tawreedTranslationService.translate("lbl_quantity_out_of_stock");
                    }else{
                        if(quantityValue <10){
                            quantityStyle= {...quantityStyle,  background: 'orange', color: 'white'}
                            quantityStatus = tawreedTranslationService.translate("lbl_quantity_low_stock");
                        }
                        else{
                            quantityStyle= {...quantityStyle,  background: '#C5FEC6', color: '#3CAB3E'}
                            quantityStatus = tawreedTranslationService.translate("lbl_quantity_available");
                        }
                    }
                }
                else{
                    quantityStyle= {...quantityStyle,  background: 'purple'}
                    quantityStatus = tawreedTranslationService.translate("lbl_quantity_not_tracked");
                }
                return (
                    <div className='inline-flex align-items-center justify-content-center'>
                        <Tag rounded
                            style={{ width: '6rem', ...quantityStyle }}
                            value={quantityStatus}/>
                        <Tag icon="pi pi-circle-fill" rounded
                            style={{ width: '6rem', ...style }}
                            value={rowData}  className='ml-1'/>
                        <TawreedInputSwitch className='ml-1' name="active" render="standalone" checked={valid} onChange={(e) => {
                            callback(id, e.target.value)
                        }} />
                    </div>)
            }, field_id, quantity_field),
    };
}
