import React from "react";
import { Panel } from "primereact/panel";
import { OverlayPanel } from "primereact/overlaypanel";
import { NavLink } from "react-router-dom";

import { tawreedTranslationService } from "../../../../../../common/translations";
import { TawreedFormField, TawreedInputPlain } from "../../../../../../common/components/form";
import { TawreedMenu } from "../../../../../../modules/constants";
import { Routes } from "../../../../../../router";
import { OrderFormPartnerInformation } from "../order-form-partner-information";
import { OrderFormHistory } from "./order-form-history";
import { GlobalContext } from "../../../../../../context";

export type OrderFormInformation1Props = {
    className: string;

    returned?: boolean;

    orderStatuses?: TawreedMenu;

    salesOrderId?: number;
    showShipment?: boolean;
    editShipment?: boolean;
    salesOfferTitle?: string;
    requestCarrierChange: () => void;
};

export const OrderFormInformation1: React.FC<OrderFormInformation1Props> = (props) => {

    const logs = React.useRef<OverlayPanel>(null);
    const customer = React.useRef<OverlayPanel>(null);
    const store = React.useRef<OverlayPanel>(null);
    const { constants: { constants } } = React.useContext(GlobalContext);

    return (
        <React.Fragment>
            <OverlayPanel ref={logs} style={{ width: '33vw' }} breakpoints={{ '960px': '60vw', '640px': '80vw' }}>
                <OrderFormHistory className="" />
            </OverlayPanel>

            <OverlayPanel ref={customer} style={{ width: '33vw' }} breakpoints={{ '960px': '60vw', '640px': '80vw' }}>
                <OrderFormPartnerInformation className="" partner="customer" />
            </OverlayPanel>

            <OverlayPanel ref={store} style={{ width: '33vw' }} breakpoints={{ '960px': '60vw', '640px': '80vw' }}>
                <OrderFormPartnerInformation className="" partner="store" />
            </OverlayPanel>
            {/* <span style={{fontSize:'1.5rem', display:'block', fontWeight:'bold'}}>
                            {tawreedTranslationService.translate('lbl_sales_orders_o_info')}
                        </span>
            <div className="ml-4"> */}
            <Panel header={<div className="flex-column">
                    <span style={{ fontSize: '1.5rem', fontWeight:'600'}}>
                        {tawreedTranslationService.translate('lbl_sales_orders_o_info')}
                    </span>
                </div>} className={props.className}>
                
            <div className="formgrid grid">
                    <div className="col-12 sm:col-6 md:col-3">
                        <TawreedFormField className="field" name="orderId" title={props.returned ? 'lbl_sales_orders_return_id' : 'lbl_sales_orders_order_id'}>
                            <TawreedInputPlain name="orderId" render="form" />
                        </TawreedFormField>
                    </div>
                    <div className="col-12 sm:col-6 md:col-3">
                        <TawreedFormField className="field" name="orderDate" title="lbl_sales_orders_order_date">
                            <TawreedInputPlain name="orderDate" render="form" mode="datetime" />
                        </TawreedFormField>
                    </div>
                    <div className="col-12 sm:col-6 md:col-3">
                        <TawreedFormField className="field" name="statusId" title="lbl_sales_orders_status">
                            <TawreedInputPlain name="statusId" render="form" mode="select" options={props.orderStatuses ?? []} className="text-primary cursor-pointer" onClick={(e) => logs.current?.toggle(e)} />
                        </TawreedFormField>
                    </div>

                    <div className="col-12 sm:col-6 md:col-3">
                        <TawreedFormField className="field" name="priceListItem.name" title="lbl_sales_orders_pricelistid">
                            <TawreedInputPlain name="priceListItem" nested="name" render="form-nested" mode="text" />
                        </TawreedFormField>
                    </div>

                    <div className="col-12 sm:col-6 md:col-3">
                        <TawreedFormField className="field" name="paymentModeId" title="lbl_sales_orders_paymentmodeid">
                            <TawreedInputPlain name="paymentModeId" render="form" mode="select" options={constants?.paymentModes ?? []} />
                        </TawreedFormField>
                    </div>

                    <div className="col-12 sm:col-6 md:col-3">
                        <TawreedFormField className="field" name="customer.partnerName" title="lbl_sales_orders_customer">
                            <TawreedInputPlain name="customer" nested="partnerName" render="form-nested" mode="text" className="text-primary cursor-pointer" onClick={(e) => customer.current?.toggle(e)} />
                        </TawreedFormField>
                    </div>

                    <div className="col-12 sm:col-6 md:col-3">
                        <TawreedFormField className="field" name="store.partnerName" title="lbl_sales_orders_store">
                            <TawreedInputPlain name="store" nested="partnerName" render="form-nested" mode="text" className="text-primary cursor-pointer" onClick={(e) => store.current?.toggle(e)} />
                        </TawreedFormField>
                    </div>
                    {
                        props.salesOfferTitle &&
                        <div className="col-12 sm:col-6 md:col-3">
                        <TawreedFormField className="field" name="salesOfferTitle" title="lbl_sales_orders_offer">
                            <TawreedInputPlain name="salesOfferTitle" render="form" mode="text" />
                        </TawreedFormField>
                    </div>

                    }
                    {
                        props.returned &&
                        props.salesOrderId &&
                        <div className="col-12 md:col-3">
                            <TawreedFormField title="lbl_sales_orders_order_id" className="field mb-0  w-full md:w-auto">
                                <NavLink to={Routes.SalesOrdersDetails.replace(":id", props.salesOrderId.toString())} className="p-button-text p-button-plain">
                                    <TawreedInputPlain render="form" name="salesOrderId" />
                                </NavLink>
                            </TawreedFormField>
                        </div>
                    }

                    {
                        !props.returned &&
                        props.showShipment &&
                        <div className="col-12 md:col-3">
                            <TawreedFormField title="lbl_sales_orders_carrier" className="field mb-0  w-full md:w-auto">
                                <TawreedInputPlain render="form-nested" name="carrier" nested="carrierName" className="text-primary cursor-pointer" onClick={props.editShipment ? () => props.requestCarrierChange() : undefined} />
                            </TawreedFormField>
                        </div>
                    }
                </div>
            </Panel>
            {/* </div> */}
        </React.Fragment>
    );
}
