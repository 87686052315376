import React, { FC, useEffect, useMemo, useState } from "react";
import { TawreedFormField, TawreedFormMode, TawreedInputNumber, TawreedInputPlain, TawreedInputSwitch } from "../../../../../common";
import { Panel } from "primereact/panel";
import { tawreedTranslationService } from "../../../../../common/translations";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import { StoreProductsService } from "../../domain";
import { QuantityDialog } from "./quantity-dialog";
import { JObject } from "../../../../../common/data/models";
import { IStockType } from "../../../../constants";

export type QuantityInfoFormProps = {
    id?: string,
    // product: StoreProductDto;
    mode: TawreedFormMode;
    // accept: (data:StoreProductDto)=>void;
    // saveProduct: (data:StoreProductDto)=>void;
    // baseProduct: ProductDto;
    //showPopup: boolean;
    initloading: boolean;
    productQauntity: ProductQauntity,
    onSaveProductQuantity?: (data: ProductQauntity) => void;
}

export type ProductQauntity = {
    quantity?: number,
    minqty?: number,
    maxqty?: number
}

export const QuantityInfoForm: FC<QuantityInfoFormProps> = ({ id, mode, initloading, productQauntity,onSaveProductQuantity }) => {

    const [inlineMode, setInlineMode] = useState<string>(
        mode === 'Create' ? 'Create' : 'View'
    );
    const [activeQuantity, setActiveQuantity] = useState({
        quantity: false,
        minqty: false,
        maxqty: false
    });
    const [loading, setLoading] = useState<boolean>(initloading);

    useEffect(()=>{
        setActiveQuantity({ quantity: productQauntity?.quantity ? true : false,
            maxqty: productQauntity?.maxqty ? true : false,
             minqty: productQauntity?.minqty ? true : false})
    },[productQauntity]);

    const [data, setData] = useState<ProductQauntity>({
        ...productQauntity
    });
    const [oldData, setOldData] = useState<ProductQauntity>({
        ...productQauntity
    });
    const [quantityDialog, setQuantityDialog] = useState<{ isOpen: boolean, quantity?: number, isEnable: boolean }>({ isOpen: false, quantity: undefined, isEnable: false, })

    const service: StoreProductsService = useMemo(() => new StoreProductsService(), []);

    const onSaveQuantity = async (): Promise<void> => {
        if (!activeQuantity.maxqty)
            data.maxqty = undefined;
        if (!activeQuantity.minqty)
            data.minqty = undefined;
        if (!activeQuantity.quantity)
            data.quantity = undefined;
        if (mode === 'Edit') {
            setLoading(true);
            await service.updateProductQuantity({
                 storeProductId: id,
                 minQuantity: data.minqty,
                 maxQuantity: data.maxqty}).then((res) => {
                setInlineMode('View');
                setLoading(false);
                onSaveProductQuantity?.(data);
            }).catch(err => setLoading(false))
        }
        else {
            //accept(data);
            return;
        }

    };
    useEffect(() => {
        if (mode === 'Create') {
            onSaveProductQuantity?.(data);
        }
    }, [data])

    const updateQuantity = async (datax: JObject) => {
        setLoading(true);
        console.log("datax",datax);
        if (!datax.type)
            datax.type = IStockType.Inbound
        console.log("datax",datax);
        await service.updateQuantity({ ...datax, storeProductId: +id! }).then((res) => {
            setInlineMode('View');
            setLoading(false);
            onSaveProductQuantity?.({...data, quantity:datax.quantity ? (datax.type === IStockType.Inbound ? datax.quantity +data.quantity : data.quantity! - datax.quantity) : null});
            setData({...data, quantity:datax.quantity ? (datax.type === IStockType.Inbound ? datax.quantity +data.quantity : data.quantity! - datax.quantity) : null});
        }).catch(err => setLoading(false))

    }
    return (
        <React.Fragment>
            <Panel header={tawreedTranslationService.translate("lbl_quantity_trackin")} className="col-12" icons={
                <React.Fragment>
                    {inlineMode === 'Edit' &&
                        <Button
                            label={tawreedTranslationService.translate("lbl_save")}
                            icon={PrimeIcons.SAVE}
                            loading={loading}
                            onClick={()=>onSaveQuantity()}
                            className={"p-button-text"}
                            style={mode === 'Create' ? { display: "none" } : {}}
                        />
                    }
                    {inlineMode === 'View' &&
                        <Button
                            label={tawreedTranslationService.translate("lbl_edit")}
                            icon={PrimeIcons.PENCIL}
                            className={"p-button-text"}
                            loading={loading}
                            onClick={() => { setOldData({...data}); setInlineMode('Edit') }} />
                    }
                    {inlineMode === 'Edit' &&
                        <Button
                            label={tawreedTranslationService.translate("lbl_cancel")}
                            icon={PrimeIcons.TIMES}
                            loading={loading}
                            className={"p-button-text"}
                            onClick={() => { setData({...oldData}); setInlineMode('View') }} />
                    }
                </React.Fragment>
            } >
                {inlineMode != 'View' ?
                    <div className="grid">

                        <TawreedFormField name="quantity" title={"lbl_quantity"} className="field col-12 md:col-9">
                            <TawreedInputNumber disabled={id ? true : (!activeQuantity.quantity)}
                                name="quantity" render="standalone" value={data.quantity} onChange={(e) => {
                                    setData({ ...data, quantity: e.value })
                                }} />
                        </TawreedFormField>
                        {id && <div className="field-checkbox col-12 md:col-3 mt-5">
                            {data.quantity !== null && <Button onClick={() => { setQuantityDialog({ isOpen: true, quantity: data.quantity, isEnable: false }) }} label={tawreedTranslationService.translate("lbl_update")} type="button" className="p-button-sm p-button-outlined mr-2" />}

                            <Button onClick={() => {
                                if (data.quantity == null)
                                    setQuantityDialog({ isOpen: true, quantity: data.quantity, isEnable: true })
                                else
                                    updateQuantity({})
                            }
                            } label={tawreedTranslationService.translate(data.quantity == null ? "lbl_enable" : "lbl_disable")} type="button" className={`p-button-sm p-button-outlined  ${data.quantity == null ? "" : "p-button-danger"}`} />
                        </div>}
                        {!id && <TawreedFormField name="activeQuantity" title="lbl_product_active" className="field-checkbox col-12 md:col-3 mt-5" flip>
                            <TawreedInputSwitch name="activeQuantity" checked={activeQuantity.quantity} render="standalone" onChange={(e) => { setActiveQuantity({ ...activeQuantity, quantity: e.value }) }} />
                        </TawreedFormField>}
                        <TawreedFormField name="minimumOrderQuantity" title={"lbl_min_order_qty"} className="field col-12 md:col-9">
                            <TawreedInputNumber disabled={!activeQuantity.minqty} name="minimumOrderQuantity" render="standalone"
                                value={data.minqty} onChange={(e) => {
                                    setData({ ...data, minqty: e.value })
                                }} />
                        </TawreedFormField>
                        <TawreedFormField name="activeMinQuantity" title="lbl_product_active" className="field-checkbox col-12 md:col-3 mt-5" flip>
                            <TawreedInputSwitch name="activeMinQuantity" render="standalone" checked={activeQuantity.minqty} onChange={(e) => { setActiveQuantity({ ...activeQuantity, minqty: e.value }) }} />
                        </TawreedFormField>
                        <TawreedFormField name="maximumOrderQuantity" title={"lbl_max_order_qty"} className="field col-12 md:col-9">
                            <TawreedInputNumber disabled={!activeQuantity.maxqty} name="maximumOrderQuantity" render="standalone"
                                value={data.maxqty} onChange={(e) => {
                                    setData({ ...data, maxqty: e.value })
                                }} />
                        </TawreedFormField>
                        <TawreedFormField name="activeMaxQuantity" title="lbl_product_active" className="field-checkbox col-12 md:col-3 mt-5" flip>
                            <TawreedInputSwitch name="activeMinQuantity" render="standalone" checked={activeQuantity.maxqty} onChange={(e) => { setActiveQuantity({ ...activeQuantity, maxqty: e.value }) }} />
                        </TawreedFormField>
                    </div> : <div className="grid">
                        <TawreedFormField name="quantity" title={"lbl_quantity"} className="field col-12 md:col-9">
                            <TawreedInputPlain disabled={!activeQuantity.quantity} name="quantity" render="standalone" value={data.quantity} />
                        </TawreedFormField>

                        <TawreedFormField name="minimumOrderQuantity" title={"lbl_min_order_qty"} className="field col-12 md:col-9">
                            <TawreedInputPlain disabled={!activeQuantity.minqty} name="minimumOrderQuantity" render="standalone" value={data.minqty} />
                        </TawreedFormField>

                        <TawreedFormField name="maximumOrderQuantity" title={"lbl_max_order_qty"} className="field col-12 md:col-9">
                            <TawreedInputPlain disabled={!activeQuantity.maxqty} name="maximumOrderQuantity" render="standalone" value={data.maxqty} />
                        </TawreedFormField>
                    </div>
                }
            </Panel>
            {quantityDialog.isOpen && <QuantityDialog isEnable={quantityDialog.isEnable}
                accept={(data) => {
                    updateQuantity(data);
                    setQuantityDialog({ isEnable: false, isOpen: false, quantity: undefined })
                }} visible={quantityDialog.isOpen} hide={() => setQuantityDialog({ isOpen: false, quantity: undefined, isEnable: false, })}></QuantityDialog>}
        </React.Fragment>
    );

}