import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import React, { FC, useState, useEffect, useContext } from 'react'
import { tawreedTranslationService } from '../../../../../common/translations';
import { TawreedFormField, TawreedInputNumber } from "../../../../../common/components/form";
import { CssUtils } from '../../../../../helpers/css';
import { AuthUtils } from '../../../../auth/domain';
import { buildCategoriesTawreedTableFormatter } from '../../../products/presentaion/components/categories-table-formatter';
import { TawreedTableFilters } from '../../../../../common/components/table/filters';
import { TawreedAction, TawreedActionName } from '../../../../../common/actions';
import { PrimeIcons } from 'primereact/api';
import { TawreedTable, TawreedTableProps } from '../../../../../common';
import { Product } from '../../../products/data';
import { Routes } from '../../../../../router';
import { ProductsService } from '../../../products/domain';
import { Link, useHistory } from 'react-router-dom';
import { GlobalContext } from '../../../../../context';
import { JObject } from '../../../../../common/data/models';


export interface ProductDialogProps {
    visible: boolean;

    hide: () => void;
    isDiscount: boolean;

    accept: (data: JObject) => void;
    isVerfied?: boolean;
    productName?:string;
    createCase: boolean;
    showCancel: boolean;
}

export const ProductDialog: FC<ProductDialogProps> = ({ visible, hide, accept, isDiscount,isVerfied,productName,showCancel, createCase }) => {


    const [dialogState, setDialogState] = useState<{ data?: JObject }>({
        data: undefined,

    });





    const ProductDialogFooter = () => {
        return (showCancel ? <div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" disabled={false} />

        </div>: <div></div>);
    }
    const ProductDialogHeader= () => {
        return (createCase ? <div>
            <p>{tawreedTranslationService.translate("lbl_product_browser_header1")}</p>
            <p>{tawreedTranslationService.translate("lbl_product_browser_header2")}</p>
        </div>: <div></div>);
    }
    const history = useHistory();
    const { constants: { constants } } = useContext(GlobalContext);
    const service: ProductsService = new ProductsService();
    const [reload, setReload] = useState(false);

    const tawreed: TawreedTableProps = {
        reload: reload,
        header: true,
        pagination: true,
        dataKey: 'productId',
        name: '',
        title: 'lbl_products',
        keyword: productName,
        ds: {
            mode: 'lazy',
            onSearch: (q) => service.search(q),
        },
        columns: [

            {
                field: "internationalCode",
                header: "lbl_product_international_code",
                sortable: true,
                filter: true,
                style: CssUtils.width('20%'),
                body: (options: any) => {
                    return(
                    <Link to={Routes.ProductsDetails.replace(':id', options.productId!.toString())} target='_blank' >{options.internationalCode}</Link>
                )}
            },
            {
                field:"displayName",
                header: "lbl_product_name",
                sortable: true,
                filter: true,
                style: CssUtils.width('30%', CssUtils.color('var(--danger-color)')),
                bodyStyle: {'direction':'ltr'},
            },
            {
                field: "categoryId",
                header: "lbl_product_category",
                sortable: true,
                filter: true,
                style: CssUtils.width('30%'),
                ...buildCategoriesTawreedTableFormatter('categoryId', constants?.productCategories ?? []),
            },
            {
                field: "actions",
                style: CssUtils.width('10%'),
                body: (options: any) => {
                    return (
                        <React.Fragment>
                            <Button onClick={() => {
                                setDialogState({ data: options })
                                accept(options);
                            }}
                            className="p-button-success p-button-outlined"
                            >{tawreedTranslationService.translate("lbl_select")}</Button>
                        </React.Fragment>)
                }
            },

        ],
        row: {
            
        },
        filter: {
            filterDisplay: 'menu',
            initial: isVerfied ? {
                'isVerified':{
                    operator: 'AND',
                    constraints: [
                        {
                            value: true,
                            matchMode: 'equals',
                        },
                    ]
                },
                'displayName': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'internationalCode': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },

                'categoryId': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        }
                    ]
                },

            }: {
                'displayName': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'internationalCode': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },

                'categoryId': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        }
                    ]
                },

            },
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'displayName',
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },

    }

    return (
        <Dialog maximizable resizable style={{ minWidth: '60%' }} 
            header={<ProductDialogHeader />} 
            visible={visible} footer={<ProductDialogFooter />} onHide={hide} draggable={false}
             position="top" closable={showCancel} closeOnEscape={showCancel}>
            <TawreedTable  {...tawreed} />
        </Dialog>
    )
}
