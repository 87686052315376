import React from "react";
import { Panel } from "primereact/panel";
import { PrimeIcons } from "primereact/api";

import { TawreedForm, TawreedFormSubmitAction, TawreedFormSubmitCallback, TawreedFormSubmitProps } from "../../../../common/components/form";
import { ReportDto, ReportService, ReportServiceKnownName, Urls } from "../../domain";
import { tawreedTranslationService } from "../../../../common/translations";
import { TawreedAction, TawreedActionName } from "../../../../common/actions";
import { TawreedReportTable } from "./table";
import { GlobalContext } from "../../../../context";
import { tawreedTableCache } from "../../../../common";

export type TawreedReportGeneratorProps = { initial?: any; title: string; name: ReportServiceKnownName; };

export const TawreedReportGenerator: React.FC<TawreedReportGeneratorProps> = (props) => {
    // di
    const { constants: { constants } } = React.useContext(GlobalContext);
    const service: ReportService = React.useMemo(() => new ReportService(), []);

    // states
    const [report, setReport] = React.useState<ReportDto>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [urls, setUrls] = React.useState<Urls>();
    const [data, setData] = React.useState<any>({ reportId: props.name, ...props.initial });
    const [actions, setActions] = React.useState<TawreedAction[]>();

    React.useEffect(
        () => {

            let mounted = true;
            if (mounted) {
                const aa: TawreedAction[] = [];
                if (urls) {
                    const onExportAs = (url: string) => {
                        return new Promise<void>((resolve, reject) => {
                            try {
                                service.export(url);
                                resolve();
                            } catch (err) {
                                reject(err);
                            }
                        });
                    }
                    aa.push(new TawreedAction(TawreedActionName.ReportsExport, 'stateless', 'lbl_reports_export_as_pdf', PrimeIcons.FILE_PDF, () => onExportAs(urls.pdfReportURI),"p-button-text"))
                    aa.push(new TawreedAction(TawreedActionName.ReportsExport, 'stateless', 'lbl_reports_export_as_excel', PrimeIcons.FILE_EXCEL, () => onExportAs(urls.xlsReportURI),"p-button-text"))
                }
                setActions(aa);
            }
            return () => { mounted = false; }

        },
        [urls, service]);

    const onSubmitExecute = (e: any, cb: TawreedFormSubmitCallback<any>): Promise<void> => {

        if (e.reportId === 'trial-balance' && e.partner)
            e.partnerName = e.partner.partnerName;
        setLoading(true);
        setReport(undefined);
        tawreedTableCache.clear('tawreed.reports.table');
        return service.generate(props.name, e, constants)
            .then(res => {
                setLoading(false);
                setReport({ ...res });
                cb({ ...e });
                setData(e);
            })
            .catch((err) => {
                setLoading(false);
                cb(err);
            });
    };

    const onSubmit: TawreedFormSubmitProps<any> = {
        showButton: true,
        resetOnSubmit: false,
        onSubmit: new TawreedFormSubmitAction<any>(TawreedActionName.ReportsGenerate, 'lbl_reports_find', PrimeIcons.SEARCH, onSubmitExecute,"p-button-text")
    };

    return (
        <TawreedForm
            className="grid form-grid"
            title={props.title}
            mode="Create"
            dataKey="" data={data} actions={actions}
            loading={loading}
            submit={onSubmit}
            useReset={!!(props.children && React.Children.count(props.children))}>

            {
                props.children &&
                React.Children.count(props.children) &&
                <Panel header={tawreedTranslationService.translate('lbl_general_information')} className="col-12" toggleable>
                    <div className="grid">
                        {
                            props.children
                        }
                    </div>
                </Panel>
            }

            {
                report &&
                <React.Fragment>
                    <div className="col-12">
                        <TawreedReportTable {...report} onUrls={e => setUrls(e)} />
                    </div>
                </React.Fragment>
            }

        </TawreedForm>
    );
}
