import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import React, { useState } from "react";
import { TawreedTable, TawreedTableProps } from "../../../../../common/components/table";
import { tawreedTranslationService } from "../../../../../common/translations";
import { CssUtils } from "../../../../../helpers/css";
import classNames from 'classnames';
import { BonusDialog } from './bonus-dialog'
import './bonus-table.scss';
import { ColumnBodyOptions } from "primereact/column";
import { TawreedTableEditorCallback } from "../../../../../common/components/table/editors";
import { IBonusType } from "../../../../constants";
import { ObjectUtils } from "../../../../../helpers/object";
import { DiscountTypesBasicsDropDown } from "./discount-types-basics";
import { GlobalContext } from "../../../../../context";
import { JObject } from "../../../../../common/data/models";
import { Panel } from "primereact/panel";


export const BonusTable: React.FC<any> = (props) => {

    const [state, setState] = useState<boolean>(false);
    const { constants: { constants } } = React.useContext(GlobalContext);


    const hide = () => {
        setState(false);
    }

    const accept = (data: { bonus: number, quantity: number }) => {
        props.addBonus({ ...data, bonusType: props.bonusType });
        hide();
    }

    const deleteBodyButton = (rowData: any, options: ColumnBodyOptions, callback: TawreedTableEditorCallback) => {

        return !props.disabeld &&  <Button
            style={{ width: "60%", height: "35px" }}
            icon={PrimeIcons.TRASH}
            className="p-button-danger p-button-text"
            loading={props.loading}
            onClick={() => {
                props.deleteBonusById(rowData.uniqueId, rowData.bonusId);
            }}
        >
        </Button>
    }
    const getSellingPrice = (data: JObject) => {
        let discountType = 'fixed';
        if (data.discountType === 'percentage')
            discountType = 'percentage';
        if (data.discountType === 'bonus')
            discountType = 'bonus';
        let discountValue = 0;
        if (data.discountValue)
            discountValue = data.discountValue;
        let sellingPrice = data.price!;
        sellingPrice -= (discountType === 'fixed' ? discountValue : (data.price! * discountValue / 100));
        if (discountType === 'bonus') {
            sellingPrice = data.price! - (data.price! * (discountValue / (discountValue + 100)));
        }
        if (sellingPrice <= 0 && discountType !== 'bonus')
            return 0;
        return sellingPrice;

    }

    const tawreed: TawreedTableProps = {
        ds: {
            mode: 'basic',
            data: props.data ? props.data : [],
        },
        name: 'lbl_store_products_bonus',
        dataKey: '',
        columns:
            props.bonusType === IBonusType.EXTRA_ITEMS ? [

                {
                    dataType: 'numeric',
                    field: "quantity",
                    header: "lbl_store_products_bonus_qty",
                    style: CssUtils.width('20%'),
                },
                {
                    dataType: 'numeric',
                    field: "bonus",
                    header: props.bonusType === IBonusType.DISCOUNT ? "lbl_store_products_discount_bonus_val" : "lbl_store_products_bonus",
                    style: CssUtils.width('20%'),
                },
                {
                    dataType: 'numeric',
                    body: deleteBodyButton,
                    style: CssUtils.width('2%'),
                }
            ] : [
                {
                    dataType: 'numeric',
                    field: "quantity",
                    header: "lbl_store_products_bonus_qty",
                    style: CssUtils.width('10%'),
                },
                {
                    dataType: 'text',
                    field: "bonusOperation",
                    header: 'lbl_bonus_operation',
                    style: CssUtils.width('10%'),
                    body: (options: any) => {
                        return constants?.bonusOperations.find(el => el.value === options.bonusOperation)?.label ?? ""
                    }
                },

                {
                    dataType: 'text',
                    field: "discountType",
                    header: 'lbl_price_scheme_discountType',
                    style: CssUtils.width('15%'),
                    body: (options: any) => {
                        return constants?.discountTypes.find(el => el.value === options.discountType)?.label ?? ""
                    }
                },
                {
                    dataType: 'numeric',
                    field: "bonus",
                    header: props.bonusType === IBonusType.DISCOUNT ? "lbl_store_products_discount_bonus_val" : "lbl_store_products_bonus",
                    className: CssUtils.className.field.amount,
                    style: CssUtils.width('15%'),
                    body: (options: any) => ObjectUtils.toLocalString(options.bonus)
                },
                {
                    dataType: 'number',
                    field: "sellingPrice",
                    header: 'lbl_sellingPrice_befor_per_unit',
                    style: CssUtils.width('20%'),
                    body: (options: any) => ObjectUtils.toLocalString(props.singlePrice)

                }, {
                    dataType: 'number',
                    field: "sellingPrice",
                    header: 'lbl_sellingPrice_befor_after_unit',
                    style: CssUtils.width('20%'),
                    body: (options: any) => ObjectUtils.toLocalString(getSellingPrice({ discountType: options.discountType, price: props.singlePrice, discountValue: options.bonus }))

                },
                {
                    dataType: 'numeric',
                    body: deleteBodyButton,
                    style: CssUtils.width('8%'),
                }

            ],
        header: { }
    };

    return (
        <React.Fragment>
             <Panel header={tawreedTranslationService.translate(props.title)} className="col-12"
             icons={
                !props.disabeld &&
                    <div className="formgroup-inline">
                        <button className={classNames(PrimeIcons.PLUS_CIRCLE,'p-button','p-button-outlined')} onClick={() => setState(true)}>{` ${tawreedTranslationService.translate('lbl_add')}`}</button>
                        {/* <i className={classNames(PrimeIcons.PLUS, 'bouns_table_plus')} onClick={() => setState(true)}></i> */}
                    </div>
             }>
             <div className="col-12 md:col-12"><TawreedTable {...tawreed} /></div>
             </Panel>
          
            <BonusDialog
                visible={state}
                singlePrice={props.singlePrice}
                hide={hide}
                isDiscount={props.bonusType === IBonusType.DISCOUNT}
                accept={accept}
            ></BonusDialog>
        </React.Fragment>
    );

}
